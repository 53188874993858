import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/other', // 点击侧边栏跳到一个单独的路由页面，需要定义，层级和其他顶级路由一样
        name: 'other',
        meta: { title: '单独的路由' },
        component: () => import('../views/Other.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '404' },
        component: () => import('../components/404.vue'),
    },
    { path: '/', redirect: '/OrderFruit' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    orderFruit: {
        path: 'orderFruit',
        name: 'orderFruit',
        meta: { title: '水果订单' },
        component: () => import('../views/OrderFruit.vue'),
    },
    orderPlot: {
        path: 'orderPlot',
        name: 'orderPlot',
        meta: { title: '地块订单' },
        component: () => import('../views/OrderPlot.vue'),
    },
    fruit: {
        path: 'fruit',
        name: 'fruit',
        meta: { title: '水果列表' },
        component: () => import('../views/Fruit.vue'),
    },
    plot: {
        path: 'plot',
        name: 'plot',
        meta: { title: '地块列表' },
        component: () => import('../views/Plot.vue'),
    },
    treeConfig: {
        path: 'treeConfig',
        name: 'treeConfig',
        meta: { title: '地块列表' },
        component: () => import('../views/TreeConfig.vue'),
    },
    banner: {
        path: 'banner',
        name: 'banner',
        meta: { title: '轮播图列表' },
        component: () => import('../views/Banner.vue'),
    },
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '主页' },
        component: () => import('../views/Home.vue'),
    },
    t1: {
        path: 't1',
        name: 't1',
        meta: { title: '表格' },
        component: () => import('../views/T1.vue'),
    },
    password: {
        path: 'password',
        name: 'password',
        meta: { title: '修改密码' },
        component: () => import('../views/Password.vue'),
    },
    msg: {
        path: 'msg',
        name: 'msg',
        meta: { title: '通知消息' },
        component: () => import('../views/Msg.vue'),
    },
    userinfo: {
        path: 'userinfo',
        name: 'userinfo',
        meta: { title: '用户信息' },
        component: () => import('../views/UserInfo.vue'),
    },
}

const createRouter = () => new Router({
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}
console.log('router:', router)
export default router