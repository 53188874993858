import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        // 左侧菜单栏数据
        menuItems: [
            {
                // name: 'order', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '订单管理', // 文本内容
                children: [

                    {
                        name: 'orderFruit', // 要跳转的路由名称 不是路径
                        size: 18, // icon大小
                        type: 'md-home', // icon类型
                        text: '水果订单', // 文本内容
                    },
                    {
                        name: 'orderPlot', // 要跳转的路由名称 不是路径
                        size: 18, // icon大小
                        type: 'md-home', // icon类型
                        text: '地块订单', // 文本内容
                    },
                ],
            },
            {
                // name: 'product', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '商品管理', // 文本内容
                children: [

                    {
                        name: 'fruit', // 要跳转的路由名称 不是路径
                        size: 18, // icon大小
                        type: 'md-home', // icon类型
                        text: '水果列表', // 文本内容
                    },
                    {
                        name: 'plot', // 要跳转的路由名称 不是路径
                        size: 18, // icon大小
                        type: 'md-home', // icon类型
                        text: '地块列表', // 文本内容
                    },
                ],
            },
            {
                // name: 'config', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '配置管理', // 文本内容
                children: [
                    // {
                    //     name: 'account', // 要跳转的路由名称 不是路径
                    //     size: 18, // icon大小
                    //     type: 'md-home', // icon类型
                    //     text: '账号', // 文本内容
                    // },
                    {
                        name: 'banner', // 要跳转的路由名称 不是路径
                        size: 18, // icon大小
                        type: 'md-home', // icon类型
                        text: '轮播图', // 文本内容
                    },
                    {
                        name: 'treeConfig', // 要跳转的路由名称 不是路径
                        size: 18, // icon大小
                        type: 'md-home', // icon类型
                        text: '果树配置', // 文本内容
                    },
                ],
            },
            // {
            //     name: 'home', // 要跳转的路由名称 不是路径
            //     size: 18, // icon大小
            //     type: 'md-home', // icon类型
            //     text: '主页', // 文本内容
            // },
            // {
            //   name: 'other', // 要跳转的路由名称 不是路径
            //   size: 18, // icon大小
            //   type: 'ios-egg-outline', // icon类型
            //   text: '单独的路由', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
            // },
            // {
            //   size: 18, // icon大小
            //   type: 'md-arrow-forward', // icon类型
            //   text: '外链',
            //   url: 'https://www.baidu.com',
            //   isExternal: true, // 外链 跳到一个外部的 URL 页面
            // },
            // {
            //   text: '二级菜单',
            //   type: 'ios-paper',
            //   children: [
            //     {
            //       type: 'ios-grid',
            //       name: 't1',
            //       text: '表格',
            //       // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
            //       // hidden: true,
            //     },
            //     {
            //       size: 18, // icon大小
            //       type: 'md-arrow-forward', // icon类型
            //       text: '外链',
            //       url: 'https://www.baidu.com',
            //       isExternal: true, // 外链 跳到一个外部的 URL 页面
            //     },
            //     {
            //       text: '三级菜单',
            //       type: 'ios-paper',
            //       children: [
            //         {
            //           type: 'ios-notifications-outline',
            //           name: 'msg',
            //           text: '查看消息',
            //         },
            //         {
            //           type: 'md-lock',
            //           name: 'password',
            //           text: '修改密码',
            //         },
            //         {
            //           type: 'md-person',
            //           name: 'userinfo',
            //           text: '基本资料',
            //         },
            //         {
            //           size: 18, // icon大小
            //           type: 'md-arrow-forward', // icon类型
            //           text: '外链',
            //           url: 'https://www.baidu.com',
            //           isExternal: true, // 外链 跳到一个外部的 URL 页面
            //         },
            //       ],
            //     },
            //   ],
            // },
        ],
    },
    mutations: {
        setMenus(state, items) {
            state.menuItems = [...items]
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading
        },
    },
})

export default store